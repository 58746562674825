<template>
  <div class="app-container-empty">
    <el-row>
      <el-col :style="{ width: 'calc(50% - 5px)' }">
        <el-card class="categoryCard">
          <div slot="header" class="cardHeader">
            <el-row>
              <el-col :span="16">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="addRootCategory"
                  >{{ $t("base.category.addRoot") }}</el-button
                >
                <el-button
                  type="primary"
                  icon="el-icon-download"
                  plain
                  size="small"
                  @click="exportCategory"
                  >{{ $t("commons.export") }}</el-button
                >
              </el-col>
              <el-col :span="8">
                <el-input
                  clearable
                  :placeholder="$t('commons.searchPhrase')"
                  maxlength="50"
                  v-model="filterText"
                >
                </el-input>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-tree
              v-loading="category.listLoading"
              :data="category.list"
              node-key="id"
              :expand-on-click-node="false"
              :highlight-current="true"
              :filter-node-method="filterNode"
              ref="tree"
            >
              <span
                class="custom-tree-node"
                slot-scope="{ node, data }"
                @mouseenter="mouseoverNode(node)"
                @mouseleave="mouseoutNode(node)"
              >
                <span @click="clickNode(data, node)">{{ node.label }}</span>
                <template v-if="node.data.show">
                  <span>
                    <el-button type="text" @click="() => appendNode(node)">
                      <i class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <template v-if="node.isLeaf">
                      <el-button
                        type="text"
                        @click="() => removeNode(node, data)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </span>
                </template>
              </span>
            </el-tree>
          </div>
        </el-card>
      </el-col>
      <el-col :style="{ width: 'calc(50% - 5px)', marginLeft: '10px' }">
        <el-card class="categoryCard">
          <el-form
            label-position="top"
            :model="category.form"
            ref="categoryForm"
            :rules="formRule"
          >
            <el-input
              class="hidden"
              type="hidden"
              v-model="category.form.categoryId"
            ></el-input>
            <el-form-item
              :label="$t('base.category.parentCategory')"
              prop="parentId"
            >
              <el-input
                type="hidden"
                class="hidden"
                v-model="category.form.parentId"
              ></el-input>
              <el-input
                v-model="category.form.parentName"
                :readonly="true"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registry.name')" prop="categoryName">
              <el-input
                v-model="category.form.categoryName"
                @input="setCode()"
                maxlength="50"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                clearable
                class="input-style"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('base.category.code')" prop="categoryCode">
              <el-input
                v-model="category.form.categoryCode"
                :placeholder="$t('commons.pleaseInput')"
                maxlength="50"
                :show-word-limit="true"
                clearable
                class="input-style"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('base.category.workTime')" prop="workTime">
              <el-input
                v-model="category.form.workTime"
                :placeholder="$t('commons.pleaseInput')"
                @input="
                  if (category.form.workTime > 999)
                    category.form.workTime = 999;
                  if (category.form.workTime < 0) category.form.workTime = 0;
                "
                type="number"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('base.category.desc')" prop="description">
              <el-input
                type="textarea"
                :rows="5"
                v-model="category.form.description"
                maxlength="160"
                :show-word-limit="true"
                :placeholder="$t('commons.pleaseInput')"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                v-loading="saveLoading"
                @click="handleCategorySave()"
              >
                {{ $t("commons.save") }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getCategoryList,
  createCategory,
  updateCategory,
  deleteCategory,
  exportCategory,
} from "@/api/business/base/tenant/category";
import { listToTree } from "@/utils/tree";
import store from "@/store/index";
import pinyin from "js-pinyin";

export default {
  name: "CategoryManager",
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      saveLoading: false,
      category: {
        listLoading: false,
        list: [],
        form: {
          categoryId: null,
          categoryCode: null,
          categoryName: null,
          parentId: -1,
          parentName: this.$t("commons.empty"),
          workTime: null,
          descCode: null,
          descName: null,
          description: null,
        },
        createLoading: false,
      },
      filterText: "",
      formRule: {
        categoryName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        categoryCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.getCategoryTree();
  },
  methods: {
    setCode() {
      let name = this.category.form.categoryName;
      this.category.form.categoryCode = pinyin.getCamelChars(name);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    getCategoryTree() {
      this.category.listLoading = true;
      getCategoryList().then((response) => {
        let data = listToTree(response, "categoryId", "parentId");
        this.recursiveCategory(data);
        this.category.listLoading = false;
      });
    },
    //递归位置列表修改属性及数据结构
    recursiveCategory(row) {
      let data = row.map((v) => {
        v.label = v.categoryName;
        v.show = false;
        v.id = v.categoryId;
        if (v.children != undefined) {
          this.recursiveCategory(v.children);
        }
        return v;
      });
      this.category.list = data;
    },
    mouseoverNode(node) {
      node.data.show = true;
    },
    mouseoutNode(node) {
      node.data.show = false;
    },
    removeNode(node, data) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteCategory({
            categoryId: data.categoryId,
            categoryName: data.categoryName,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            //异步更新
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex((d) => d.id === data.id);
            children.splice(index, 1);
            this.clearCategoryForm();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    clickNode(data) {
      this.category.form = Object.assign({}, data);
      if (data.parentId < 0) {
        this.category.form.parentName = this.$t("commons.empty");
      }
    },
    appendNode(node) {
      this.clearCategoryForm();
      this.category.form.parentId = node.data.categoryId;
      this.category.form.parentName = node.data.categoryName;
    },
    clearCategoryForm() {
      this.category.form = {
        categoryId: null,
        categoryCode: null,
        categoryName: null,
        parentId: -1,
        parentName: this.$t("commons.empty"),
        workTime: null,
        descCode: null,
        descName: null,
        description: null,
      };
      this.$refs.categoryForm.resetFields();
    },
    handleCategorySave() {
      if (this.saveLoading) return;
      this.$refs.categoryForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          if (this.category.form.categoryId == null) {
            createCategory(this.category.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.getCategoryTree();
                this.clearCategoryForm();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            updateCategory(this.category.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.getCategoryTree();
                this.clearCategoryForm();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.saveLoading = false;
              });
          }
        }
      });
    },
    addRootCategory() {
      this.clearCategoryForm();
    },
    exportCategory() {
      exportCategory().then((msg) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId: msg,
          taskName: "Category",
          taskStatus: 0,
          rootPath: "basePath",
        };
        //将导出任务丢入导出任务列表中
        store.dispatch("PushExportNotice", exportObj);
      });
    },
  },
};
</script>

<style scoped>
.categoryCard {
  height: calc(100vh - 90px);
}

.categoryCard >>> .el-card__header {
  padding: 6px 10px;
}

.categoryCard >>> .el-card__body {
  overflow-y: auto;
  height: calc(100%);
}

.categoryCard >>> .el-tree {
  margin-bottom: 50px;
}

.cardHeader {
  line-height: 34px;
  font-weight: 600;
}

::v-deep .input-style .el-input__inner {
  padding-right: 69px;
}

::v-deep .input-style .el-input__suffix {
  background: #fff;
  height: 95%;
  top: 1px;
}
::v-deep .el-input__inner {
  line-height: 1px !important;
}
</style>
